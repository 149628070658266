.appBar {
  position: relative;
  z-index: 100;
  box-shadow: 0 2px 3px rgba(0,0,0,.3)!important;
  background-color: #fff;
}

.rc-slider-mark-text{
  font-size: 8px;
  white-space: nowrap;
}

.appBarTitle {
  text-align: center;
  color: #519626;
  font-size: 2.5em;
  font-variant: all-petite-caps;
  font-weight: 100;
  padding: 15px 0px;
}

.trends {
  position: fixed;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0px);
  border-radius: 2px !important;
  border: 1px solid white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3) !important;
  background-color: white;
  padding: 15px;
}

.sliderContainer {
  padding: 16px 40px 4px 40px;
  height: 40px;
  background-color: #c9d2d340;
  border: 1px solid #e4e8e5;
}

.fromVersion {
  display: inline;
  left: 0px;
}

.toVersion {
  position: absolute;
  display: inline;
  right: 10px;
}

.footerBar {
  position: fixed;
  left: 50%;
  border-radius: 2px !important;
  border: 1px solid #639c4b;
  transform: translate(-50%, 0px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.footerBarTitle {
  background-color: #649b4c;
  border-right: 1px solid white;
  text-align: center;
  padding: 6px;
  font-size: 15px;
  color: white;
  font-variant: all-petite-caps;
  font-family: revert;
}

.footerBarContent {
  padding: 2px 5px;
  font-size: 10px;
  background-color: white;
  white-space: nowrap;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  padding: 0px !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
}

.mapboxgl-popup-tip {
  display: none !important;
}
.logoImg{
  padding: 10px;
  width: 250px;
  position: absolute;
}

.countryPopup .name {
  padding: 1px 8px;
  background: #ced8d8;
  min-width: 40px;
  border-bottom: 1px solid #b8c5c5;
  color: #687071;
}

.countryPopup .stats {
  padding: 2px 4px;
  text-align: center;
  background-color: #889798;
  color: white;
}

.statusDiv {
  display: inline;
}

.stat {
  font-size: 14px;
}

.added {
  color: green;
}

.removed {
  color: #a25b28;
}

.changed {
  color: #b7c9d1;
  font-size: 10px;
}

.no_change {
  color: #b7c9d1;
}
.paPopupContent {
  padding: 10px;
}
#popup {
  position: absolute;
  background-color: #9baaac;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  margin-left: -20px;
  color: white;
}
.rt-thead.-header{
  min-width: 500px!important;
}
.ReactTable .rt-thead.-header {
  box-shadow: none!important;
  border-bottom: 2px solid #8b999a;
  font-weight: 700;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  font-size: 10px!important;
}
.wdpaPopup {
  font-size: 12px;
}


.PAPopup {
  z-index: 3;
}

.PAPopupList {
  z-index: 2;
}

.paPopupListHeader {
  background: whitesmoke;
  padding: 3px;
}

.wdpaPopupListItem:hover {
  background-color: whitesmoke;
}

.wdpaPopup a {
  color: unset;
  text-decoration: none;
}

.paPopupContent {
  padding: 10px;
}


.paPopupName {
  padding: 10px;
  background-color: #dfe7e8;
  border-bottom: 1px solid #9baaac;
  font-weight: 500;
  color: #819092;
}

.paPopupRow {
  padding: 3px;
}

.ppLink {
  padding-left: 8px;
  float: right;
  font-size: 10px;
}

.underline {
  cursor: pointer;
  text-decoration: underline;
}

.paPopupHeaderRow {
  font-style: italic;
}

.geometryChangeDiv {
  padding: 5px 0px;
}

.statusCheckboxContainer {
  display: inline;
  padding: 4px;
}

.statusCheckbox, .statusLabel {
  vertical-align: middle;
  cursor: pointer;
}

.ZoomOutMap {
  cursor: pointer;
  font-size: 20px !important;
  padding-right: 10px;
  padding-top: 1px;
  color: #91c04d;
}

.viewTrends {
  cursor: pointer;
  font-size: 15px !important;
  color: gray;
}

.rc-slider-rail {
  background-color: #64a04085!important;
  height: 2px!important;
  margin-top: 1px !important;
}
.rc-slider-track {
  background-color: #659a4d!important;
}
.rc-slider-handle {
  border: solid 2px #5d9442!important;
}

.rc-slider-dot {
  border: 1px solid #a8c998!important;
}

.statsHolder {
  padding: 12px;
  text-align: center;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  font-weight: 700;
  color: #797471;
  display: flex;
  justify-content: center;
}

.statsBar {
  display: inline;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(2) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #399131!important;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(3) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a25b28!important;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(4) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a6b7b9!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(2) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #399131!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(3) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a25b28!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(4) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a6b7b9!important;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.countryName {
  font-size: 14px;
  padding-right: 10px;
  /* vertical-align: middle; */
  color: #91c04d;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);
}

.vMiddle {

}

.sparklineHolder {
  right: 5px;
  position: absolute;
}

.statusImage {
  vertical-align: middle;
  transform: scale(0.7);
}

.wcmcChangeLog {
  position: absolute;
  left: 13px;
}
.softwareVersion {
  position: absolute;
  top: 14px;
  right: 7px;
  font-size: 14px;
  color: #91ad82;
}

.wdpaAttributeTable{
  height:200px;
  overflow: auto;
}

table.changeTable {
  margin: 1em;
  border: 1px solid #7b7b7b;
}

table.changeTable * {
  padding: 0.5em 1em;
}

button.mapboxgl-ctrl-attrib-button {
  display: none;
}
